@charset 'UTF-8';
/*----------------------------------------
    Pricing Plan  Page
------------------------------------------*/
.plans-container .collection
{
    border: none;
}

.plans-container .collection .collection-item
{
    font-size: 1.07rem;
    line-height: 1.6em;

    text-align: center;

    border-bottom: none;
}

.plans-container .collection .collection-item:before
{
    font-family: 'Material Icons';
    font-size: 1.28rem;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1.6em;

    margin-right: 10px;

    content: 'check';
    text-transform: none;

    color: #00bfa5;

    speak: none;
    -webkit-font-smoothing: antialiased;
}

.plans-container .card .card-image
{
    position: relative;

    width: 100%;
}

.plans-container .card .card-image .card-title
{
    font-size: 1.28rem;
    line-height: 1.6em;

    position: relative;

    width: 100%;
    margin-bottom: 0;
    padding: 10px 15px;

    text-align: center;
    text-transform: uppercase;

    background: rgba(0, 0, 0, .1);
}

.plans-container .card .card-image .price
{
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.6em;

    position: relative;

    text-align: center;

    color: #fff;
}

.plans-container .card .card-image .price sup
{
    font-size: 1.42rem;
    font-weight: 100;
    line-height: 1.6em;

    top: -35px;
}

.plans-container .card .card-image .price sub
{
    font-size: 1.42rem;
    font-weight: 100;
    line-height: 1.6em;

    top: 0;
    left: -15px;
}

.plans-container .card .card-image .price sub span
{
    margin-left: .15rem;
}

.plans-container .card .card-image .price-desc
{
    padding-bottom: 10px;

    text-align: center;

    color: #fff;
}

.plans-container .card .card-content
{
    padding: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .plans-container .collection .collection-item:before
    {
        content: '✔';
    }
}

.featurelist li{
    font-size: 14px!important;
    padding: 3px 10px!important;
}

@media screen and (max-width: 600px){
    .hideSM{
        display: none;
    }
}
